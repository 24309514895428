import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin, faGithub, faMedium , faStrava} from '@fortawesome/free-brands-svg-icons';
import "./footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container container">
        <h1 className="footer__title">
          find me
        </h1>
        <div className="footer__social">
          <a href="https://www.instagram.com/siddiq_odiq/" className="footer__social-icon" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} />
          </a>

          <a href="https://www.linkedin.com/in/odiq/" className="footer__social-icon" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>

          <a href="https://github.com/siddiqodiq" className="footer__social-icon" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faGithub} />
          </a>

          <a href="https://medium.com/@siddiqodiq" className="footer__social-icon" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faMedium} />
          </a>
         {/* <a href="https://strava.app.link/0KEdrvXtpNb" className="footer__social-icon" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faStrava} />
          </a> */}
        </div>
        <span className="footer__copy">
          Syubbanul Siddiq - 2024
        </span>
      </div>
    </footer>
  );
}

export default Footer;
