import logo from './logo.svg';
import './App.css';
import React from 'react';
import Header from './components/header/Header';
import Home from './components/home/Home';
import About from './components/about/About';
import Gallery from './components/gallery/Gallery';
import Writing from './components/writing/Writing';
import Footer from './components/footer/Footer';

function App() {
  return (
    <>
      <Header/>
      <main className='main'>
        <Home/>
        <About/>
        <Gallery/>
        <Writing/>
        <Footer/>
      </main>
    </>
  )
}

export default App;
