import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogleScholar } from '@fortawesome/free-brands-svg-icons';
import parse from 'html-react-parser';
import './writing.css';

const Writing = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  // Manual input for Google Scholar publications
  const scholarPublications = [
    {
      title: "Research Article",
      description: "Check out my other research articles",
      url: "https://scholar.google.com/citations?user=66EGQbgAAAAJ&hl=id"
    }
  ];

  useEffect(() => {
    const fetchMediumArticles = async () => {
      try {
        const response = await fetch('https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@siddiqodiq');
        const data = await response.json();
        const mediumArticles = data.items.slice(0, 3).map(item => ({
          ...item,
          type: 'medium'
        }));
        
        setArticles(mediumArticles);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching Medium articles:', error);
        setLoading(false);
      }
    };

    fetchMediumArticles();
  }, []);

  const truncateHTML = (html, maxLength) => {
    const parsed = parse(html);
    let plainText = '';
    
    const extractText = (node) => {
      if (typeof node === 'string') {
        plainText += node;
      } else if (node.props && node.props.children) {
        React.Children.forEach(node.props.children, extractText);
      }
    };

    if (Array.isArray(parsed)) {
      parsed.forEach(extractText);
    } else {
      extractText(parsed);
    }

    if (plainText.length <= maxLength) return html;
    return plainText.slice(0, maxLength) + '...';
  };

  return (
    <section className="writing section" id="writing">
      <h2 className="section__title">Writing</h2>
      <span className="section__subtitle">My Articles and Research</span>

      <div className="writing__container container">
        {loading ? (
          <p>Loading content...</p>
        ) : (
          <div className="writing__content">
            <div className="writing__medium">
              {articles.map((item, index) => (
                <div key={index} className="writing__card">
                  <h3 className="writing__title">{item.title}</h3>
                  <div className="writing__description">
                    {parse(truncateHTML(item.description, 100))}
                  </div>
                  <a href={item.link} target="_blank" rel="noopener noreferrer" className="button button--flex writing__button">
                    Read on Medium
                    <i className="uil uil-arrow-right button__icon"></i>
                  </a>
                </div>
              ))}
            </div>
            <div className="writing__scholar">
              {scholarPublications.map((item, index) => (
                <div key={index} className="writing__card writing__card-scholar">
                  <h3 className="writing__title">{item.title}</h3>
                  <p className="writing__description">{item.description}</p>
                  <a href={item.url} target="_blank" rel="noopener noreferrer" className="button button--flex writing__button">
                    <FontAwesomeIcon icon={faGoogleScholar} className="writing__scholar-icon" />
                    View Profile
                  </a>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Writing;
